import { SET_CURRENT_SECTION } from "./currentSection-action";

const initialState ={
    currentSection: 0,
}

export const currentSectionReduсer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_CURRENT_SECTION: 
            return {
                ...state,
                currentSection: payload,
            }
        default: 
            return state;
    }
}
