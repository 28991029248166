import React from 'react';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useParams } from 'react-router-dom';
import '/node_modules/video-react/dist/video-react.css';
import { Player, BigPlayButton, LoadingSpinner, ControlBar, PlaybackRateMenuButton } from 'video-react';
import { useDispatch } from 'react-redux';
import { setCurrentVideo } from '../store/currentVideo/currentVideo-action';
import { Link } from 'react-router-dom';
import { setCurrentSection } from '../store/currentVideo/currentSection-action';

function SingleVideoPage(props) {
  const {videoId} = useParams();
  const currentVideo = props.videos.filter(item => item.id === videoId);
  const dispatch = useDispatch();
  dispatch(setCurrentVideo(currentVideo.id));
  dispatch(setCurrentSection(currentVideo.section_id));

  const nextVideoId = Number(videoId) + 1;
  const prevVideoId = Number(videoId) - 1;
  let nextVideo = props.videos.filter(item => item.id ==  nextVideoId);
  let prevVideo = props.videos.filter(item => item.id == prevVideoId);
  
  return ( 
    <>
      <h2 className='mb-4'>{currentVideo[0].name}</h2>
      <Player
        playsInline
        src={currentVideo[0].url}
        className='mb-5'
      >
        <BigPlayButton position="center" />
        <LoadingSpinner />
        <ControlBar>
          <PlaybackRateMenuButton rates={[2, 1.5, 1]} />
        </ControlBar>
      </Player>
      <Stack direction="horizontal" gap={3}>
        {prevVideo.length !== 0 ? <Link className='' to={`/section/${prevVideo[0].section_id}/${prevVideo[0].id}`} >
          <Button variant="primary">Предыдущий урок</Button>
          </Link> : null}
        {nextVideo.length !== 0 ? <Link className='' to={`/section/${nextVideo[0].section_id}/${nextVideo[0].id}`} >
          <Button variant="primary">Следующий урок</Button>
        </Link> : null}
        <Link className='ms-auto' to={`/section/${currentVideo[0].section_id}`} >
          <Button variant="primary">Назад в раздел</Button>
        </Link>
      </Stack>
    </>
  );
}

export default SingleVideoPage;