import { SET_CURRENT_VIDEO } from "./currentVideo-action";

const initialState ={
    currentVideo: 0,
}

export const currentVideoReduсer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_CURRENT_VIDEO: 
            return {
                ...state,
                currentVideo: payload,
            }
        default: 
            return state;
    }
}
