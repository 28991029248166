import { createStore, compose } from "redux";
import { loadState, saveState } from './local-storage';
import { rootReducer } from "./root-reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  || compose;
const persistedState = loadState();
const store = createStore(rootReducer, persistedState, composeEnhancers())

store.subscribe(() =>{
    saveState({
        currentVideo: store.getState().currentVideo,
        currentSection: store.getState().currentSection,
    });
  });

export {store}
