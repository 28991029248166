import {Routes, Route} from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import VideoCatalog from "./components/VideoCatalog";
import VideoSection from './components/VideoSection';
import SingleVideoPage from './components/SingleVideoPage'
import * as dataVideoCatalog from './database/sections.json';
import * as dataAllVideos from './database/videos.json';

function App() {
  const videoSections = dataVideoCatalog;
  const allVideos = dataAllVideos;
  return (
    <>
      <Header />
      <Container>
        <Routes>
          <Route path="/" element={<VideoCatalog videoSection={videoSections.sections} />} />
          <Route path="/section/:id" element={<VideoSection videos={allVideos.videos} videoSection={videoSections.sections}/>} />
          <Route path="/section/:id/:videoId" element={<SingleVideoPage videos={allVideos.videos}/>} />
        </Routes>
      </Container>
    </>
  );
}

export default App;
