import { Link } from 'react-router-dom';

function VideoCatalog(props) {
   return ( 
    <div className="catalog_video">
      <h2 className='mb-4'>Современный React - с Нуля до Профессионала (2023)</h2>
      { 
        props.videoSection.length ? props.videoSection.map((item,i) => (
          <div className='mb-2' key={i}>
            <Link to={`/section/${item.id}`} > 
              {item.id}. {item.title}
            </Link>
          </div>
        )) : null
      }
    </div> 
  );
}
  
export default VideoCatalog;