import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Header() {
  return ( 
    <div className="header">
          <Navbar bg="light" data-bs-theme="light">
            <Container>
              <Navbar.Brand href="/">На главную</Navbar.Brand>
              <Nav className="me-auto">
                <Nav.Link href=" https://op.ppkf.ru/">OpenProject</Nav.Link>
                <Nav.Link href="https://op.ppkf.ru/projects/razvitiie/wiki/bibliotieka">Библиотека</Nav.Link>
                <Nav.Link href="https://ppkf.ru/react/">React</Nav.Link>
                <NavDropdown title="Пет-проекты" id="navbarScrollingDropdown">
                  <NavDropdown.Item href="http://grigorevili.ru" target="_blank">Илья Григорьев</NavDropdown.Item>
                  <NavDropdown.Item href="http://kanbekovsr.ru" target="_blank">Сергей Канбеков </NavDropdown.Item>
                  <NavDropdown.Item href="#" target="_blank">Вадим Клочков </NavDropdown.Item>
                  <NavDropdown.Item href="http://kravchenkoay.ru" target="_blank">Александр Кравченко</NavDropdown.Item>
                  <NavDropdown.Item href="http://amamishev.ru" target="_blank">Александр Мамишев</NavDropdown.Item>
                  <NavDropdown.Item href="http://maximt.ru" target="_blank">Максим Тодышев</NavDropdown.Item>
                  <NavDropdown.Item href="http://achistik.ru" target="_blank">Алексей Чистяков</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Container>
          </Navbar>
    </div>
  );
}

export default Header;