import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCurrentSection } from '../store/currentVideo/currentSection-action';

function VideoSection(props) {
  const {id} = useParams();
  const currentSection = id;
  const dispatch = useDispatch();
  dispatch(setCurrentSection(currentSection));

  return ( 
    <>
      <h2 className='mb-4'>{props.videoSection[id-1].title}</h2>
      {
        props.videos.length ? props.videos.map((item,i) => (
            item.section_id === currentSection ?
              <div className='mb-2' key={i}>
                <Link to={`/section/${id}/${item.id}`} > 
                  {item.id}. {item.name}
                </Link>
              </div> : null
        )) : null
      }
    </>
  );
}

export default VideoSection;